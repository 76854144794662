.btn-bg {
  background: linear-gradient(90deg, #FF8600 0%, #FFB732 100%);
}

.btn-bg-hero-image {
  background: linear-gradient(90deg, #FFD56D 0%, #FFF5D3 100%);
  box-shadow: 0px 16px 32px 0px #00000012;
  border-radius: 30px;
  border: none;
  padding: 8px 16px 8px 16px;
}


.btn-contact-us {
  padding: 8px 24px 8px 24px;
  border-radius: 5px;
  border: none;
}

.btn-contact-us-hero-section {
  padding: 12px 40px 12px 40px;
  border-radius: 5px;
  border: none;
}

@include media-breakpoint-down(sm) {
  .btn-contact-us-hero-section {
    padding: 8px 16px 8px 16px;
    border-radius: 5px;
    border: none;
  }
}

.btn-see-details {
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  border: none;
}

.btn-i-interested-selected {
  background: #FF8600;
  border-radius: 5px;
}


.btn-border-color-theme-primary-2 {
  //border-color: #FF8600;
  border: 1px solid #FF8600;
  border-radius: 50%;
}

.btn-border-color-theme-primary-1 {
  border-color: #535E5D;
}


.button-border {
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  //border-color: linear-gradient(90deg, #FF8600 0%, #FFD56D 100%);
}

.btn-cafe-gradient {
  border-image: linear-gradient(90deg, #FF8600 0%, #FFD56D 100%) 1;
}

.gaming-button-book-now {
  border: 1px solid #FF8600;
  border-radius: 5px;
}
