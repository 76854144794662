.bg-embrace-community-container {
  background: linear-gradient(90deg, rgba(255, 134, 0, 0.15) 0%, rgba(255, 213, 109, 0.15) 100%);
}

.bg-secure-your-spot-gradient {
  background: linear-gradient(102.64deg, #818F8D 100%, #535E5D 100%);
}

.review-container-box-shadow {
  box-shadow: 0px 0px 32px 0px #00000012;
}

.images-bg-shadow {
  box-shadow: 0px 16px 32px 0px #00000012;
}

.home-images-bg-shadow {
  box-shadow: 0px 16px 32px 0px #00000033;
}

.about-images-box-shadow {
  box-shadow: -10px 18px 15px 0px #535E5D26;
}

// border radius
.br-40 {
  border-radius: 40px;
}

.br-20 {
  border-radius: 20px;
}

.br-10 {
  border-radius: 10px;
}

.br-5 {
  border-radius: 5px;
}
//background color

.bg-color-light-dark {
  background: #E5E8E7;
}

.bg-color-orange-500 {
  background-color: #FF9E0A;
}

.bg-color-orange-300 {
  background-color: #FFD56D;
}

.bg-color-orange-100 {
  background-color: #FFF5D3;
}

.bg-color-orange-50 {
  background: #FFFAEC;
}

.bg-color-theme-primary-1 {
  background: #535E5D;
}

.bg-color-theme-primary-2 {
  background: #FF8600;
}

.bg-light-sky-color {
  background: #1977F31A;
}

.bg-light-pink-color {
  background: #FE277A1A;
}

.bg-light-grey-color {
  background: #0000001A;
}

//nav bar

.bg-nav-bar-container {
  background: linear-gradient(90deg, #EAEAEA 0%, #D4C4B1 100%);
  box-shadow: 0px 16px 32px 0px #00000012;
  padding: 12px 24px 12px 24px;
  border-radius: 50px;
}

//home

.bg-hero-home-image-container {
  background-image: url("../../../../public/images/landing/home-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-amenities-container {
  //background-image: url("../../../../public/images/landing/bg-dark-color-cafeteria-image.png"), url("../../../../public/images/landing/bg-cafeteria-image.png");
  background-size: contain, cover;
  background-position: left;
  background-repeat: no-repeat;
}

.bg-secure-your-spot-container {
  background-image: url("../../../../public/images/landing/bg-secure-your-spot.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-secure-your-spot-content-container {
  background-image: url("../../../../public/images/landing/bg-couple-image.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.bg-happy-users-container {
  //background-image: url("../images/landing/bg-happy-users-animation.gif");
  //background-position: center;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  background-image: radial-gradient(at 60% 23%, #FEB1B1 0px, transparent 50%),
  radial-gradient(at 83% 24%, #C0F4FF 0px, transparent 50%),
  radial-gradient(at 60% 85%, #E5D0FF 0px, transparent 50%),
  radial-gradient(at 87% 72%, #FEEFB7 0px, transparent 50%);
  //background-size: 300% 300%;
}

//@keyframes my_animation {
//  0% {
//    background-position: 0% 0%, 0% 0%, 0% 0%, 0% 0%;
//  }
//  25% {
//    background-position: 80% 30%, 80% 30%, 80% 30%, 80% 30%;
//  }
//  50% {
//    background-position: 100% 100%, 100% 100%, 100% 100%, 100% 100%;
//  }
//  75% {
//    background-position: 20% 100%, 20% 100%, 20% 100%, 20% 100%;
//  }
//  100% {
//    background-position: 0% 0%, 0% 0%, 0% 0%, 0% 0%;
//  }
//}

//.bg-happy-users-container radial-gradient {
//  animation: rotateColors 10s linear infinite;
//}

// about page
.bg-status-card-container {
  background: linear-gradient(360deg, #FFEEB0 0%, #FFFFE5 100%);
  border: none;
  border-bottom: 5px solid #FF8600;
  padding: 32px 24px 32px 24px;
  gap: 8px;
}

@include media-breakpoint-down(sm) {
  .bg-status-card-container {
    padding: 16px ;
  }
}

.bg-our-values-container {
  background-color: #FFF5D3;
  .img-container {
   filter: grayscale(1);
  }
}

@include media-breakpoint-down(sm) {
  .bg-our-values-container {
    background-color: #FFD56D;
    .img-container {
      filter: grayscale(0);
    }
  }
}

@include media-breakpoint-up(md) {
  .bg-our-values-container:hover {
    background-color: #FFD56D !important;
    .img-container {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}



.bg-secure-spot-container {
  background-image: url("../../../../public/images/about/bg-secure-your-spot-image.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

//contact us

.bg-contact-us-section {
  background-image: url("../../../../public/images/contact-us/bg-contact-us-section.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-contact-container {
  background-image: url("../../../../public/images/contact-us/bg-contact-container.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@include media-breakpoint-down(sm) {
  .bg-contact-container {
    background-image: url("../../../../public/images/contact-us/bg-mobile-contact-container.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}


//footer section

.bg-footer-color {
  background: #242828;
}


// nav tabs home page

.active-property-tab {
  background-color: #FFFFFF !important;
}

.non-active-property-tab {
  background-color: transparent !important;
}


// cafe

.bg-cafe-hero-image {
  background-image: url('../../../../public/images/cafe/cafe-bg-hero-image.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

@include media-breakpoint-down(sm) {
  .bg-cafe-hero-image {
    height: 60vh;
    background-image: url('../../../../public/images/cafe/bg-cafe-mobile-image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.cafe-location-container {
  background: #FFFFFF26;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 40px;
}

.bg-discount-container {
  background: linear-gradient(90deg, #461E04 0%, #CC6202 100%);
  border-radius: 10px;
}

.discount-note-container {
  background: #FFFFFF4D;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
}

.our-cuisines-box-shadow {
  border-radius: 10px;
  box-shadow: 0px 0px 32px 0px #0000001A;
}

.menu-item-border {
  border-bottom: 1px dashed #E5E8E7;
}

// gaming

.bg-gaming-hero-image {
  background-image: url("../../../../public/images/gaming/bg-gaming-hero-section-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

@include media-breakpoint-down(sm) {
  .bg-gaming-hero-image {
    //background-image: url("../../../../public/images/gaming/bg-gaming-hero-section-image.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
  }
}

.bg-gaming-discount-container {
  background: linear-gradient(90deg, #006901 0%, #1ACC1A 100%);
  border-radius: 10px;
}

.gaming-discount-label {
  background: linear-gradient(90deg, #0066FF 0%, #6DB9FF 100%);
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 24px;
}
