/* Bar Color */
#nav, .nav-tabs, .nav-item a{
  background-color: #F5F6F6;
}
//
//#nav {
//  //border-top: 1px solid #E5E8E7;
//  border-bottom: 1px solid #E5E8E7;
//}


body ,html{
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif !important;
}


.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding: 8px 16px 8px 16px !important;
  gap: 8px !important;
  border: none !important;
  color: #424847 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #FF8600 !important;
  padding: 8px 16px 8px 16px !important;
  gap: 8px !important;
  background-color: #FFFFFF !important;
  border-top: 2px solid #FF8600 !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #E5E8E7 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

//.nav .nav-pills, .nav-link.show .nav-link {
//  background-color: transparent;
//}
//
//.nav .nav-link.active, .nav-pills .nav-item.show .nav-link {
//  background-color: #FFFFFF;
//}


.tab-custom .nav-link.active {
  background-color: red; /* Change to your desired color */
  color: white; /* Change to your desired color */
}

.tabs-heading-container {
  padding: 8px 0px 8px 0px;
  gap: 8px;
  border: none;
  background: linear-gradient(-90deg, #FFFFFF 20%, #FFE7A5 100%);
}

@include media-breakpoint-up(md) {
  .tabs-content-container:hover {
    box-shadow: 0px 16px 32px 0px #00000012;
  }
}


.rent-container {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #E5E8E7
}

.view-photos-container {
  margin-top: -50px !important;
}


::placeholder {
  color: #ABB5B3 !important;
  font-size: 14px;
  font-weight: 500;
  font-family: 'inter', serif;
}


.amenities-animation-container {

  img {
    transition: transform 0.5s ease; // Transition for smooth rotation
  }

  .background-image {
    opacity: 0; // Initially hidden
    transition: opacity 0.5s ease; // Transition for smooth opacity change
  }

  &:hover {

    img:last-child {
      transform: translateX(60%) translateY(10%) rotate(-20deg); // Rotate first image negatively on hover
    }
    .background-image {
      opacity: 1; // Make second image visible on hover
    }

    .rotate-in-left {
      animation: rotateInLeft 1s ease forwards;
    }
  }
}


@keyframes rotateInLeft {
  0% {
    transform: translateX(0%) rotate(0deg); // Start from the left
  }
  100% {
    transform: translateX(0%) translateY(20%) rotate(0deg); // Move to the original position
  }
}

.gym-animation-container {
  position: relative;
  width: auto;

  .gym-animation-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.5s ease; // Transition for smooth rotation
  }

  .gym-animation-background-img {
    opacity: 0; // Initially hidden
    transition: opacity 0.5s ease; // Transition for smooth opacity change
  }

  &:hover {

    img:last-child {
      transform: translateX(50%) translateY(10%) rotate(-20deg);; // Rotate first image negatively on hover
    }
    .gym-animation-background-img {
      opacity: 1; // Make second image visible on hover
    }
    .gym-animation-rotate-in-left {
      animation: gymAnimationRotateInLeft 1s ease forwards;
    }
  }
}


@keyframes gymAnimationRotateInLeft {
  0% {
    transform: translateX(0%) rotate(0deg); // Start from the left
  }
  100% {
    transform: translateX(0%) translateY(20%) rotate(0deg); // Move to the original position
  }
}


.hero-image {
  animation: slideImage 1500ms ease-in-out infinite alternate;
}

@keyframes slideImage {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(10%);
  }
}

.button-image-animation {
  animation: slideImageButton 1500ms ease-in-out infinite alternate;
}

@keyframes slideImageButton {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(30%);
  }
}


// about

.embrace-togetherness-container {
  margin-top: -50px !important;
}

@include media-breakpoint-down(sm) {
  .hero-bottom-images-container {
    margin-top: -60px;
  }
}

.our-values-image-radius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@include media-breakpoint-down(sm) {
  .our-values-image-radius {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }
}

.our-values-left-image-radius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@include media-breakpoint-down(sm) {
  .our-values-left-image-radius{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
  }
}

.team-member-disable {
  display: none !important;
}

.team-member-active {
  display: block !important;
}


.team-member-image {
  filter: grayscale(1);
}

.team-member-image-is-active {
  filter: grayscale(0);
}

.nav-tabs-scrollable {
  overflow-x: auto !important;
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}

.nav-tabs-scrollable::-webkit-scrollbar {
  display: none !important; /* Hide the scrollbar for Chrome, Safari, and Opera */
}

.rooms-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.nav-tabs-scrollable .nav-item {
  display: inline-block !important;
}

.aira-property-swiper .swiper-pagination-bullet {
  /* Adjust the height of the bullet */
  background-color: #FFFFE5; /* Set the background color of the bullet */
  opacity: 0.5; /* Adjust the opacity of the bullet */
}

.aira-property-swiper .swiper-pagination-bullet-active {
  background-color: #FFFFFF; /* Set the background color of the active bullet */
  opacity: 1;
}

.bhuvan-property-swiper .swiper-pagination-bullet {
  /* Adjust the height of the bullet */
  background-color: #FFFFE5; /* Set the background color of the bullet */
  opacity: 0.5; /* Adjust the opacity of the bullet */
}

.bhuvan-property-swiper .swiper-pagination-bullet-active {
  background-color: #FFFFFF; /* Set the background color of the active bullet */
  opacity: 1;
}

.active-slide {
  display: none;
}

.image-border {
  border: 10px solid #FFF5D3;
}

.properties-container {
  cursor: pointer;
}

.aira-property-swiper .swiper-button-next,
.aira-property-swiper .swiper-button-prev,
{
  color: #FFFFFF !important;
}

.aira-property-swiper [class^="swiper-button-"]::after{
  font-size: 20px;
  border: 2px solid #FFFFFF;
  padding: 10px 15px 10px 15px;
  border-radius: 100%;
  margin: auto;
}

//.bhuvan-property-swiper [class^="swiper-button-"]::after{
//  font-size: 20px;
//  border: 2px solid #FFFFFF;
//  padding: 10px 15px 10px 15px;
//  border-radius: 100%;
//  margin: auto;
//}


.scroll-gradient {
  //background: transparent;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.aira-property-swiper [class^="swiper-button-"]{
  width: 50px;
}

.bhuvan-property-swiper [class^="swiper-button-"]{
  width: 50px;
}


.image-negative-rotation {
  rotate: -8deg;
}

.image-positive-rotation {
  rotate: 8deg;
}


.about-image-border {
  border: 5px solid #FFE7A5;
}


@include media-breakpoint-up(md) {
  .property-tabs-container {
    height: 80vh;
    overflow-y: scroll;
  }

  .property-tabs-container::-webkit-scrollbar {
    display: none;
  }
}

.food-image-radius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@include media-breakpoint-down(sm) {
  .food-image-radius {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
  }
}
